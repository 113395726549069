<template>
    <vant-nav-bar-enlist></vant-nav-bar-enlist>
     <div class="login">  
       <h2 align="center" style="margin-bottom: 30px;">个人信息表</h2>
<table style="border: 1px;margin: 0 auto;background: snow; width: 347px;" cellpadding="5" cellspacing="0" >
 
<tr>
 
<td>姓名(必填)</td>
 
<td><input type="text" v-model="fApplicant"  style="width:95%"></td>
 
<td style="width:35px">性别</td>
 
 <!-- 0：男，1女 -->
<td>
  <select style="width: 96%" ref="sexs" @change="select">
  <option selected="selected">请选择</option>
      <option value="男">男</option>
      <option value="女">女</option>
  </select>
</td>
 
</tr>
 
<tr>
<td>民族</td>
<td><input type="text" v-model="fNation" style="width:95%;margin-top: 10px;" ></td>

<td>年龄</td>
<td><input type="text"  v-model="fApplicantage"  style="width:95%"></td>
 
</tr>
 

<tr>
 
<td style="width:64px">手机号码(必填)</td>
 
<td><input type="tel" v-model="fPhone" style="width:95%;margin-top:10px"></td>
 
</tr>


<tr>
 
 
<td>邮箱</td>
 
<td><input  v-model="fMailbox" style="width: 130%;margin-top: 10px; " type="text"></td>
 
</tr>


 
<tr>
 
<td>专业</td>
 
<td colspan="3"><input v-model="fMajor" type="text" style="width: 98%; margin-top: 10px;" ></td>
 
</tr>
 
<tr>
 
<td>毕业院校</td>
 
<td colspan="3"><input  v-model="fSchool" type="text" style="width: 98%; margin-top: 10px;" ></td>
 
</tr>
 
<tr>
 
<td>地址</td>
 
<td colspan="3"><input v-model="fSignaddress" type="text" style="width: 98%; margin-top: 10px;" ></td>
 
</tr>

<!-- <tr>
 
<td>上传简历</td>
 
<td colspan="3"><input type="file" id="fFile" @change="getFile" style="width: 98%; margin-top: 40px;" ></td>

</tr> -->


<tr>
 
<td>上传简历</td>
<van-uploader :after-read="afterRead"  accept=".doc,.docx,.txt,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" />
</tr>

</table>
      <div  ref="Ishow" style="display: none;">
          <div ref="update">
            <van-datetime-picker
            v-model="currentDate"
            @cancel="cancel()"
            @confirm="confirm()"
            type="datetime"
            title="业务咨询添加"
            :min-date="minDate"
            :max-date="maxDate" />
        </div>
      </div>
        <div style="margin: 43px; margin-top: 69px;">
         <van-button round block type="primary" native-type="submit" @click="submit"> 提交</van-button>
        </div>
  </div>
   <div id="btn" ref="btns" :data-clipboard-text="copyInfo">
          </div>
       <van-popup v-model:show="show"  >内容已经已复制</van-popup>
</template>


<script>
import VantNavBarEnlist from '../vant_nvabar/VantNavBarEnlist.vue'
import {getappy,getCateDetail} from '../../../../network/index'
import { DatetimePicker,Dialog} from 'vant';
import { reactive, ref } from 'vue';
import $ from 'jquery'
// getCurrentInstance获取组件实例
import {onMounted,getCurrentInstance} from 'vue'
import {useRouter} from 'vue-router'
import ClipboardJS from 'clipboard'
//获取当前时间
  export default {
    name:'myEnlist',
    setup() {
       //复制的内容
        const copyInfo = ref("")
      //弹框默认是false
       const show = ref(false);
      //当前时间
        const currentDate = ref("");
        const router =  useRouter();
        // 当前登录的id
        const userId =  router.currentRoute.value.query.userId
        //楼层位置
        const location = reactive({});
        //获取实例
         const  getInstance  = getCurrentInstance()
         //楼盘的hourseId
        const housesId = window.localStorage.getItem("hourseid")
        //发起网络请求：通过楼层Id,查询出当前的楼层
        getCateDetail(housesId).then(res=> {
          location.Info = res.location
          console.log(res.location)
        })
        //v-model实例对象
         const proxy = reactive({})
        const fApplicant = ref('')
        const fNation = ref('')
        const fApplicantage = ref('')
        const fPhone = ref('')
        const fMailbox = ref('')
        const fMajor = ref('')
        const fSchool = ref('')
        const fSignaddress = ref('')
        const fApplicantsex = ref('')
       // const data = reactive({})
         var param=new FormData();
       onMounted (() => {
        // 定义组件实例
        proxy.Info = getCurrentInstance()
	    })
        const afterRead = (file) => {
           param.append("file",file.file)
                // 此时可以自行将文件上传至服务器
            //data.info =  file.file
          };
      //获取下拉框
        function select() {
            fApplicantsex.value = getInstance.refs.sexs.value
        }

        
       function submit() {
        //  var re = /^1\d{10}$/
          var re = /^[1][3,4,5,7,8,9][0-9]{9}$/;
          if(fApplicant.value =='' || fPhone.value == '') {
            alert("请输入正确的用户信息")
          }else if(!re.test(fPhone.value)){
            alert("手机格式错误")     
          }else {
            param.append("fApplicant",fApplicant.value)
            param.append("fPhone",fPhone.value)
            param.append("userId",userId)
            param.append("housesId",housesId)
            param.append("fApplicantsex",fApplicantsex.value)
            param.append("fApplicantage",fApplicantage.value)
            param.append("fNation",fNation.value)
            param.append("fMajor",fMajor.value)
            param.append("fSignaddress",fSignaddress.value)
            param.append("fSchool",fSchool.value)
            param.append("fMailbox",fMailbox.value)
           //var intention = proxy.Info
             function btn(val) {
                var clipboard = new ClipboardJS('#btn');
                copyInfo.value = val
                clipboard.on('success', function(e) {
                    console.info('Action:', e.action);
                    console.info('Text:', e.text);
                    console.info('Trigger:', e.trigger);
                    console.log(e)
                    show.value = true;
                });
                clipboard.on('error', function(e) {
                    console.error('Action:', e.action);
                    console.error('Trigger:', e.trigger);
                    console.log(e)
                });
            
            }
          getappy(param).then(res=> {
                    if(res.status == '200') {
                      console.log(res.status)
                    // currentPageUrl.value = res.Info
                    Dialog.alert({
                          title: '标题',
                          message:
                          res.Info,
                      }).then(() => {
                        btn(res.Info)
                        setTimeout(()=>{
                      proxy.Info.refs.btns.click()
                      //重定向到详情页面
                         router.push({path: `/detail/${housesId}`,query: {userId: userId}})
                      },1)}).catch(() => {});
                   
                   
              }

           })
        }
              } 
      return {
          minDate: new Date(2020, 0, 1),
          maxDate: new Date(2025, 10, 1),
          currentDate,location,
          userId,housesId,submit,confirm,onMounted,copyInfo,show,select,
          fApplicant,fPhone,fMailbox,fSignaddress,fApplicantsex,fNation,fApplicantage,fMajor,fSchool,afterRead
      }
    },
    components: {
        VantNavBarEnlist
    },

  }

</script>
<style scoped>
  .yuyue {
      display: none;
      margin-bottom: 24px;
      margin-top: 11px;
      margin-left: 56px;
      cursor: pointer;
      width: 99px;
  }
  .yuyue span {
      padding: 6px;
      border: 1px solid;
  }
 .uname,.passw {
    position: relative;
    font-size: 40px;
    top: 9px;
    left: 8px;
}
    .login {
    margin: 0px auto;
    width: 355px;
    margin-top: 40px;
  }
  .inputusername,.inputpassword{   
    width: 224px;
    height: 45px;
    margin-bottom: 10px;
    outline: none;
    padding: 10px;
    font-size: 13px;
    color: #111;
    border-top: 1px solid #312E3D;
    border-left: 1px solid #312E3D;
    border-right: 1px solid #312E3D;
    border-bottom: 1px solid #56536A;
    border-radius: 4px;
    background-color: #f6f2f257;
  }   
  .but{   
    width: 202px;
    min-height: 20px;
    display: block;
    background-color: #4a77d4;
    border: 1px solid #3762bc;
    color: #fff;
    padding: 9px 14px;
    font-size: 15px;
    line-height: normal;
    border-radius: 5px;
    /* margin: 0; */
    margin-left: 35px;
}   
</style>