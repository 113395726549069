<template>
    <h3>登录页面</h3>
    <div class="">
        <div class="form_login">
<div class="form_logo"><img src="../../../assets/img/register/hours_03.gif" /><br /></div>
<form method="post" role="form" id="form_login">

    <div class="form-group">
        <i class="fa fa-user"></i>
    <input type="text" v-model="password" class="form-control" name="phone" ref="phone" @blur="phone" placeholder="手机号" autocomplete="off">
    </div>

    <div class="form-group">
        <i class="fa fa-key"></i>
    <!-- <input type="password" class="form-control" name="password" ref="password" @blur="password" placeholder="密码" autocomplete="off"> -->
      <input type="password" v-model="username" class="form-control" name="password" ref="password" @blur="password" placeholder="密码" autocomplete="off">
    </div>
    <div class="form-group form-groups" style="height:25px; line-height:25px;">
    <!-- <input type="checkbox" class="" name="checkbox" id="checkbox" placeholder="checkbox" autocomplete="off">
    <label class="checkfont" for="checkbox" >记住我的帐号</label> -->
    </div>
    <div class="form-group">
    <button type="submit" class="btn btn-primary btn-block btn-login" @click.prevent="login">登录</button>
    </div>
</form>
    <div class="hovertree_form_footer">&copy; 实习系统 </div>
    </div>
    <div class="prompt">
         <span id="sphone" ref="sphone"></span>
    </div>
     <div class="prompt1">
         <span id="sp" ref="sj"></span>
    </div>
     <!-- <p class=reg @click="reg">点击注册</p> -->
    </div>
</template>

<script >
import $ from 'jquery'
import {getlogin} from '../../../network/index'
import {useMainStore} from '../../../store/index.js'
export default {
    name: "MyLogin",
    data() {
        return {
            checked: true,
                  info : [],
                  username: '',
                  password: ''
        }
    },
    components: {},
    methods: {   
         getCookie: function() {
      if (document.cookie.length > 0) {
        var arr = document.cookie.split("; "); //这里显示的格式需要切割一下自己可输出看下
        console.log(arr )
        for (var i = 0; i < arr.length; i++) {
          var arr2 = arr[i].split("="); //再次切割
          this.info.push(arr2[1])
        }
      }
      this.username =  this.info[1]
      console.log(this.username)
      this.password =  this.info[0]
      console.log(this.password)
    }, 
        // 手机号校验
        phone() {
             const phone = this.$refs.phone.value
             var  re = /^1\d{10}$/
              if(phone == '' || !re.test(phone)) { 
              //  document.getElementById("sj").innerHTML("手机号码格式正确");
                    $("#sphone").html("手机号码格式错误");
                    $("#sphone").css("color","red"); 
            }else {
                    $("#sphone").html("");
                    $("#sphone").css("color","green"); 
            }
            
        },
        //登录
         login() {
              const phone = this.$refs.phone.value
              const password = this.$refs.password.value
            //   var re = /^1\d{10}$/
             var re = /^[1][3,4,5,7,8,9][0-9]{9}$/;
            getlogin(phone,password).then(res => {
               //登录成功
               if(res.status == 400) {
                   //账号不存在
                   alert("账号不存在")
                     //清空Cookie
                    this.clearCookie();
               }else if(res.status ==300 ) {
                   //密码错误
                   alert("密码错误")
                     //清空Cookie
                    this.clearCookie();
               }else if(!re.test(phone)){
                alert("手机格式错误")   
                  //清空Cookie
                  this.clearCookie();  
               }else if(res.status == 200){
                  if (this.checked == true) {
                            // console.log("checked == true");
                            //传入账号名，密码，和保存天数3个参数
                    this.setCookie(phone, password, 7);
                    }
                //第三种设置方式：
            //    var storage=window.localStorage;
            //     storage.setItem("user",res);
                localStorage.setItem("id", res.msg.id);
                localStorage.setItem("username", res.msg.name);
                localStorage.setItem("password", res.msg.username);
                alert("登录成功")
                const mainstore =  useMainStore();
                mainstore.user = res
                // this.$router.push('/layout/home')
                 this.$router.push({path:`/layout/home`,query: {userId: res.id}})
               }
           })  
        },
                //清除cookie
    clearCookie: function() {
      this.setCookie("", "", -1); //修改2值都为空，天数为负1天就好了
    },

     setCookie(c_name, c_pwd, exdays) {
      var exdate = new Date(); //获取时间
      exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * exdays); //保存的天数
      //字符串拼接cookie
      window.document.cookie =
        "userName" + "=" + c_name + ";path=/;expires=" + exdate.toGMTString();
      window.document.cookie =
        "userPwd" + "=" + c_pwd + ";path=/;expires=" + exdate.toGMTString();
    },
    },
     mounted() {
    this.getCookie();      
    }
}
</script>

<style scoped>
h3 {
    text-align: center;
    font-size: 22px;
    margin-top: 20px;
}
#sphone {
    position: relative;
    right: -226px;
    bottom: 344px;
}
#sp {
    color: red;
    position: relative;
    right: -226px;
    bottom: 270px;
}
.prompt {
    position: absolute;
}
.prompt1 {
    position: absolute;
}
body {
    margin: 0;
    padding: 0;
    color: #666;
    background: #c1d2fe;
    font-family: Verdana, sans-serif
}

.form_login {
    max-width: 640px;
    margin: auto;
    text-align: center;
    padding-top: 80px;
}

.form_logo {
    margin-bottom: 48px;
}

.form-group {
    width: 355px;
    margin: 0 auto;
    height: 50px;
    margin-bottom: 20px;
}

.form-group .fa {
    display: block;
    width: 50px;
    height: 50px;
    float: left;
}

.form-group .form-control {
    display: block;
    width: 300px;
    height: 48px;
    float: left;
    border: 1px solid #ccc;
    padding: 0;
    margin-left: 0;
    text-indent: 1em;
    themeColor: #00a988;
}

.form-group .form-control:hover {
    border: 1px solid #0160A0;
}

.form-group .checkfont {
    color: #666;
}

.form-group .btn {
    width: 350px;
    height: 50px;
    background-color: #0160A0;
    border: 0px;
    color: #fff;
    font-size: 14px;
}

.fa-user {
    background: url(http://hovertree.com/texiao/mobile/10/images/user.png) no-repeat center;
}

.fa-key {
    background: url(http://hovertree.com/texiao/mobile/10/images/pw.png) no-repeat center;
}

.hovertree_form_footer {
    margin-top: 100px;
    font-size: 14px;
    color: #5B809A;
}
.form_logo{font-size:26px;}a{color:gray}
.reg {
            /* padding-left: 30px; */
    margin-right: 25px;
    font-size: 18px;
    text-align: right;
    margin-top: -97px;
    /* margin-top: 0; */
    margin-bottom: 1rem;
    }
 .reg:hover {
   cursor: pointer;
 }
 img {
    margin-top: -37px;
    /* width: 200px; */
    /* border: 0; */
    width: 200px;
    height: 114px;
 }

 .form-groups {
    height: 25px;
    line-height: 25px;
    text-align: right;
    margin-left: 3px;
 }
</style>