<template>
    <div class="title">
        <van-nav-bar class="cate_entail"
            title='招工详情'
            left-text="返回"
            left-arrow
            @click-left="onClickLeft"/>
    </div>
     <div class="null"></div>
</template>

<script >
export default {
    name: "MyVantNB",
    props: {
        title: {
            type: String,
            default: ''
        }
    },
    setup() {
    const onClickLeft = () => history.back();
    return {
      onClickLeft,
    };
  },
    data() {
        return {}
    },
    components: {},
    methods: {}
}
</script>

<style scoped>
    .title {
        position: fixed;
        left: 0px;
        right: 0px;
        top: 0px;
    }
    .null {
        height: 44px;
    }
     .cate_entail {
        --van-nav-bar-background-color: teal;
        --van-nav-bar-text-color: white;
        --van-nav-bar-title-text-color: white;
        --van-nav-bar-icon-color:  white;
    }
</style>
