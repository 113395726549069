<template>
    <div class="" >
        <vant-nav-bar></vant-nav-bar>
         <div class="main">
             <div  v-for="item in detaillist" :key="item.id" class="content">
                <p>城市：{{item.city}}</p>
                <p>地址：{{item.location}}</p>
                <div v-if="item.video" class="div_video">
                    视频： <video ref="realtimeVideo" class="video-js video-viewer__video" :src="item.video" controls="controls" autoplay />
                </div>
                <div v-else>
                      <div class="detail-img">
                        照片：<br /> <img :src="item.path" alt="" />
                      </div>detail
                </div>
                     描述：<p v-html="item.description" class="contentdetail"></p>
             </div>
        </div>
        <!-- 我要报名 -->
        <van-button type="primary" block class="block" @click="btn">我要报名</van-button>
    </div>
</template>
<script >
 import VantNavBar from '../details/vant_nvabar/VantNavBar.vue'
 import { getDetail } from '../../../network/index'
 import {reactive, ref} from 'vue'
 //引入路由函数
import { useRouter } from "vue-router";
export default {
    setup() {
        const detaillist = reactive([]);
        const router =  useRouter();
        const id =  router.currentRoute.value.params.id
        getDetail(id).then(res => {
            const newItem = { ...res }
            detaillist.push(newItem)
        })  
        // 点击跳转到我要报名页面
         //传递参数(原因，移动端不行UserId丢失，所以就要使用)
        const userId =  router.currentRoute.value.query.userId
        const btn = () => {
            router.push({name: 'enlist',params: {hourseid: router.currentRoute.value.params.id},query: {userId: userId}})
              //点击跳转到详情页面:把房子的id存储的
             window.localStorage.setItem("hourseid",router.currentRoute.value.params.id);
        }

       
        return {
            detaillist,btn
        }
    },
    name: "MyDeatail",
    components: {
                // DetailList,
                VantNavBar,
                },
   
}
</script>

<style scoped>
.div_video {
    width: 300px;
    height: 257px;
    margin-bottom: 45px;
}

.video-js {
    width: 100%;
    height: 100%;
}

.contentdetail /deep/ img {
    width: 90% !important;
    margin:0 auto;
}
   .main {
       width: 300px;
        /* height: 300px; */
        margin: 37px auto;
        /* display: flex;
        justify-content: center; */
   }
   .block {
        margin: auto;
        border-radius: 10%;
        width: 271px;
   }
    .main p{
        word-break: break-word;
    }

    .detail-img {
        width: 100%;
        height: 200px;
        /* border: 1px solid; */
        margin-bottom: 34px;
    }

     .detail-img img{
        width: 100%;
        height: 100%;
    }
</style>
