<template>
    <div class="">
        <p>我的下线</p>
        <vant-nav-bar></vant-nav-bar>
        <p v-for="item in person.Info">
             <van-contact-card type="edit" :name="item.fApplicant" :tel="item.fPhone" :sex="item.housesId.city"  :editable="false"></van-contact-card>
              <div style="margin-left: 41px; position: absolute; color: black;">招工名字：{{item.housesId.city}}</div>
        </p>
    </div>
</template>

<script >
import VantNavBar from '@/components/common/vant_nvabar/VantNavBarRegin.vue'
import {getagent} from '../../../network/index'
import {reactive, ref} from 'vue'
export default {
    name: "MyAgent",
    setup() {
         const userId=window.localStorage.getItem("id")
         const person = reactive({})
         //发起网络请求
         console.log(userId)
             const sex = ref('张三');
        //法一、
        //  getagent({"userId":userId}).then(res=> {
        //   //  person.Info = res
        //      console.log(res)
        // })
        //法二、
              getagent(userId).then(res=> {
               person.Info = res
              console.log(res)
               
         })
        return {
            userId,person,sex
        }
    },
    components: {VantNavBar},
}
</script>

<style scoped>
 .van-contact-card:before {
    position: absolute;
    right: 0;
    bottom: 0;
    top: 7px;
    left: 0;
    height: 1px;
    background: repeating-linear-gradient(-45deg,var(--van-warning-color) 0,var(--van-warning-color) 20%,transparent 0,transparent 25%,var(--van-primary-color) 0,var(--van-primary-color) 45%,transparent 0,transparent 50%);
 }


 .van-contact-card {
        padding-bottom: 3px;
}
</style>