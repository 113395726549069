import axios  from "axios";

export function request(config) {
    const instance = axios.create({
        //baseURL: 'apo',
        baseURL: 'https://apo.51zxcc.com/',
        timeout: 9000,
    })

    instance.interceptors.request.use(config => {
        // 为请求头对象添加token验证的Authorization字段
       // config.headers.Authorization = window.sessionStorage.getItem('token')
        return config
    },
        err => {
            console.log(err)
        })


    instance.interceptors.response.use(res => {
        return res.data
    },
        err=> {
        console.log(err)
    })


    return instance(config)
}