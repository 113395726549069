<template>
    <div class="profile">
     <!-- 切换 -->
       <div class="Infomation">
            <nav-bar>
                <template v-slot:center>我的信息</template>
        </nav-bar>
         <personal :list=list></personal>
       </div>
    </div>
</template>

<script >
import NavBar from '../../components/common/navbar/NavBar.vue' 
import {getinformation} from  '../../network/index'
import {useMainStore} from '../../store/index'
import personal from './personal/Personal.vue'
import {reactive, ref} from 'vue'
export default {
    name: "MyProfile",
    setup() {
        // const list = reactive([])
        // const mainstore =  useMainStore();
        // //根据当前用户id，查询数据
        // var id = mainstore.user.id;
        //  getinformation(id).then(res=> {
        //         console.log(res)
        //        if(res.name!=null) {
        //            const newItem = { ...res }
        //            list.push(newItem)
        //        }

        //     })
        return {
            // list
        }
    },
    components: {
        NavBar,
        personal
    },
}
</script>

<style scoped>
     .profile {
        background-color: red;
        color: #ffff;
    }
    .Infomation {
        font-size: 14px;
        padding: 0px 4px;
    }
    .router-active {
        color: red;
        border-bottom: 1px solid red;
    }

  
</style>
